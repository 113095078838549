// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '../stores/useAuthStore';
import SellerListPage from '../views/SellerListPage.vue';
import MainPage from '../views/MainPage.vue';
import AccountPage from '../views/AccountPage.vue';
import SellerForm from '../views/SellerForm.vue';
import AboutPage from '../views/about/AboutPage.vue';
import CreateShop from '../views/about/CreateShop.vue';
import { useWebApp } from 'vue-tg';

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
  },
  {
    path: '/sellers',
    name: 'SellerList',
    component: SellerListPage,
  },
  {
    path: '/account',
    name: 'AccountPage',
    component: AccountPage,
  },
  {
    path: '/seller/add',
    name: 'AddSeller',
    component: SellerForm,
  },
  {
    path: '/seller/:sellerId/edit',
    name: 'EditSeller',
    component: SellerForm,
  },
  {
    path: '/about/servise',
    name: 'AboutPage',
    component: AboutPage,
  },
  {
    path: '/about/create-shop',
    name: 'CreateShop',
    component: CreateShop,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard to check authentication
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  console.log('Checking authentication for route:', to.name);

  const isAuthenticated = await authStore.isAuthenticated();

  if (!isAuthenticated) {
    console.log('User not authenticated, attempting login...');
    try {
      const { initDataUnsafe } = useWebApp();
      const managerId = String(initDataUnsafe?.user.id);
      const name = initDataUnsafe?.user.first_name;

      if (managerId) {
        const credentials = { name: name, telegram_user_id: managerId };
        await authStore.login(credentials);
        console.log('Login successful');
        next();
      } else {
        console.error('Manager ID is not available. Authentication cannot proceed.');
        next(false);
      }
    } catch (error) {
      console.error('Failed to authenticate:', error);
      next(false);
    }
  } else {
    console.log('User is already authenticated');
    next();
  }
});



export default router;
