<template>
  <BackButton />
  <div>
    <h1 class="text-xl font-medium mt-2 mb-6">{{ isEditing ? 'Редактирование кабинета' : 'Добавление нового кабинета' }}</h1>
    <p v-if="resultMessage" :class="resultMessageClass" class="text-sm mb-4">{{ resultMessage }}</p>
    <div class="space-y-4">
      <div class="form-group">
        <input
          type="text"
          id="name"
          v-model="seller.name"
          required
          placeholder="Название кабинета на Avito"
          class="mt-1 block w-full p-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        <div class="text-xs font-normal text-slate-400 p-2">Напишите название кабинете чтобы видеть его в списке. Название кабинета используется при оплате подписки.</div>
      </div>
      <div class="form-group">
        <input
          type="url"
          id="sellerId"
          v-model="seller.sellerId"
          @input="extractSellerId"
          required
          placeholder="Ссылка на личный кабинет"
          class="mt-1 block w-full p-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        <p v-if="errorMessage" class="text-red-500 text-sm mt-1">{{ errorMessage }}</p>
        <div class="text-xs font-normal text-slate-400 p-2">
          <i class="bi bi-exclamation-triangle-fill text-warning"></i> Вставьте ссылку на профиль Avito. В поле автоматически добавится только идентификатор профиля. На телефоне ссылку можно получить с помощью кнопки "Поделиться" на странице профиля. На компьютере перейдите на страниу всех товаров в кабинете.
        </div>
      </div>
      <div class="form-group">
        <input
          type="text"
          id="token"
          v-model="seller.telegraph_bots.token"
          placeholder="Токен бота"
          class="mt-1 block w-full p-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        <div class="text-xs font-normal text-slate-400 p-2">
          <i class="bi bi-exclamation-triangle-fill text-warning"></i> Для работы магазина вам понадобится токен вашего бота. Получить его легко за пару минут не выходя из Telegram. Вы можете получить его в специальном боте @BotFather. Создайте нового бота в нем и скопируйте полученный токен. Токен вы можете добавить после добавления кабинета, просто отредактировав его.
        </div>
      </div>
      <button
        type="button"
        @click="handleSubmit"
        class="w-full inline-flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-slate-800 bg-lime-300 hover:bg-lime-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600"
      >
        {{ isEditing ? 'Изменить' : 'Добавить' }}
      </button>
      <button
        type="button"
        @click="showDeletePopup"
        v-if="isEditing"
        class="w-full inline-flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-slate-400 bg-slate-100 hover:bg-slate-200"
      >
        Удалить
      </button>
    </div>
  </div>
  <Popup v-if="showPopup" message="Вы уверены, что хотите удалить?" :buttons="popupButtons" @close="handlePopupClose" />
</template>

<script>
import { ref } from 'vue';
import { useSellerStore } from '../stores/useSellerStore';
import BackButton from '../components/BackButton.vue';
import { Popup } from 'vue-tg';

export default {
  components: {
    BackButton,
    Popup,
  },
  props: {
    sellerData: {
      type: Object,
      default: () => ({
        sellerId: '',
        name: '',
        telegraph_bots: null,
      }),
    },
  },
  data() {
    return {
      seller: {
        ...this.sellerData,
        telegraph_bots: this.sellerData.telegraph_bots || { token: '' },
      },
      errorMessage: '',
      resultMessage: '',
      resultMessageClass: '',
      showPopup: false, // State to control popup visibility
      popupButtons: [
        { id: 'yes', type: 'destructive', text: 'Да' },
        { id: 'cancel', type: 'cancel', text: 'Отмена' },
      ],
    };
  },
  computed: {
    isEditing() {
      return this.$route.name === 'EditSeller';
    },
  },
  methods: {
    extractSellerId(event) {
      const url = event.target.value;
      const urlParams = new URLSearchParams(url.split('?')[1]);
      let sellerId = urlParams.get('sellerId');

      if (!sellerId) {
        const pathMatch = url.match(/user\/([^/]+)\/profile/);
        if (pathMatch && pathMatch[1]) {
          sellerId = pathMatch[1];
        }
      }

      if (sellerId) {
        this.seller.sellerId = sellerId;
        this.errorMessage = '';
      } else {
        this.errorMessage = 'Параметр sellerId в ссылке не найден, вставьте ссылку с параметром sellerId';
      }
    },
    async fetchSellerData() {
      const sellerStore = useSellerStore();
      const sellerId = this.$route.params.sellerId;
      if (sellerId) {
        const seller = sellerStore.sellers.find(s => s.sellerId === sellerId);
        if (seller) {
          this.seller = {
            ...seller,
            telegraph_bots: seller.telegraph_bots || { token: '' },
          };
        }
      }
    },
    async handleSubmit() {
      if (!this.seller.sellerId) {
        this.resultMessage = 'Поле "Ссылка на личный кабинет" не заполнено.';
        this.resultMessageClass = 'text-red-500';
        return;
      }

      const sellerStore = useSellerStore();
      if (this.isEditing) {
        sellerStore.updateSeller(this.seller);
        this.resultMessage = 'Кабинет успешно обновлён.';
        this.resultMessageClass = 'text-green-500';
      } else {
        const result = await sellerStore.addSeller(this.seller);
        if (result.success) {
          this.$router.push({ name: 'SellerList', query: { message: 'Кабинет и товары селлера добавлены' } });
        } else {
          this.resultMessage = result.message;
          this.resultMessageClass = 'text-red-500';
        }
      }
    },
    showDeletePopup() {
      this.showPopup = true;
    },
    handlePopupClose(buttonId) {
      this.showPopup = false;
      if (buttonId === 'yes') {
        this.handleDelete();
      }
    },
    async handleDelete() {
      const sellerStore = useSellerStore();
      const message = await sellerStore.deleteSeller(this.seller.sellerId);
      this.$router.push({ name: 'SellerList', query: { message } });
    },
  },
  mounted() {
    this.fetchSellerData();
  },
};
</script>

<style scoped>
/* No additional styles needed as Tailwind CSS is used */
</style>
