<template>
    <BackButton />
    <div class="p-6">
        <h1 class="text-2xl font-bold mb-4">Telegram Seller</h1>
        <div class="text-base text-slate-700 mb-2">
            <p>Уважаемые продавцы, представляем вам уникальный сервис автоматизации, который <span class="font-medium">перенесет ваши товары из кабинетов Avito, Ozon и Wildberries прямо в Telegram</span>.</p> <p>Теперь вы можете управлять своим ассортиментом и взаимодействовать с покупателями быстрее и эффективнее, чем когда-либо!</p>
            <div class="my-6 text-lg font-medium">Почему стоит выбрать Telegram Seller?</div>
            <div class="mb-2 text-md"><span class="font-medium">Молниеносный перенос товаров:</span> Ваши товары будут доступны в Telegram всего через 5 минут после загрузки.</div>
            <div class="mb-2 text-md"><span class="font-medium">Автоматическое обновление:</span> Всегда актуальная информация о ваших товарах без лишних усилий.</div>
            <div class="mb-2 text-md"><span class="font-medium">Прямое взаимодействие с покупателями:</span> Общайтесь с клиентами напрямую через Telegram и увеличивайте продажи.</div>
            <div class="mb-2 text-md"><span class="font-medium"></span>Онлайн-оплата заказов: Поддержка безопасных и быстрых платежей после подтверждения наличия товара.</div>

            <div class="my-6 text-lg font-medium">В Telegram Seller вам доступно</div>
            <div class="mb-2 text-md">Создание неограниченного количества магазинов Telegram</div>
            <div class="mb-2 text-md">В каждом магазине не ограниченное количество товаров.</div>
            <div class="mb-2 text-md">Низкая стоимость подписки на Telegram Seller равная двум чашкам кофе.</div>
            <div class="mb-2 text-md">Отзывчивая техническая поддержка.</div>
        </div>
        

        <router-link :to="{ name: 'CreateShop' }" >
                    <div class="text-md font-medium cursor-pointer p-2 my-6 rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white text-center">
                        Как создать магазин? <i class="bi bi-arrow-right"></i>
                    </div>
        </router-link>
    </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';

export default {
    components: {
        BackButton,
    },
};
</script>

<style scoped>
</style>
