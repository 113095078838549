<!-- src/views/AccountPage.vue -->
<template>
    <BackButton />
    <div>
      <section>
        <h2 class="text-xl mb-2 font-medium">Персональная информация</h2>
            <ul class="mb-3">
                <li>Иван Иванов</li>
                <li>+7 (123) 456-78-90</li>
            </ul>
            
            <button class="border border-slate-300 rounded px-4 py-2 mb-4">Редактировать</button>
            
      </section>
      <section>
        <h2 class="text-xl mb-2 font-medium">История платежей</h2>
            <div class="payment-history">
                <div v-for="(item, index) in 5" :key="index" class="border border-slate-300 rounded p-3 mb-2">
                    <p class="mb-2">Иван Иванов</p>                
                    <div class="flex justify-between">
                        <p>01.01.2023</p>
                        <p>1000 ₽</p>
                        <span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Успешно</span>
                    </div> 
                </div>
            </div>
      </section>
    </div>
  </template>


  
  <script>

import BackButton from '../components/BackButton.vue';


  export default {
    name: 'AccountPage',
    components: {
      BackButton,
  },
  };
  </script>
  