<template>
  <div class="bg-white border bg-slate-50 rounded-lg">
    
    <div class="flex items-center p-2 mb-4 rounded-t-lg bg-gradient-to-r from-[#615f9c] via-[#6175dd] to-[#735ec6]">
      <CabinetLogo :iconName="iconName" width="20" height="20" />
      <div class="text-sm font-semibold text-white ml-2">{{ name }}</div>
    </div>
    <div class="flex justify-between items-center mb-4 px-2">
      <div class="flex justify-end items-center space-x-2 ml-auto">
        <span v-if="subscriptionStatus" class="bg-green-100 text-slate-800 px-3 text-xs font-medium h-12 rounded-md flex items-center justify-center">
          Оплачен до {{ formattedPaymentToDate }}        
        </span>
        <span v-else class="bg-red-100 text-slate-800 px-3 text-slate-800 h-12 rounded-md text-xs font-medium flex items-center justify-center">
          Не оплачено
        </span>
        <div
          class="h-12 w-12 bg-white border rounded-md flex items-center justify-center cursor-pointer transition-colors duration-300 ease-in-out"
          @click="togglePaymentOptions"
        >
          <i :class="{ 'font-bold': isClicked }" class="bi bi-credit-card text-lg"></i>
        </div>
        <div
          class="w-12 h-12 bg-white border rounded-md flex items-center justify-center cursor-pointer transition-colors duration-300 ease-in-out"
          :class="{ 'bg-lime-100': isClicked }"
          @click="handleButtonClick"
        >
          <i :class="{ 'font-bold': isClicked }" class="bi bi-link-45deg text-lg"></i>
        </div>

        <router-link :to="{ name: 'EditSeller', params: { sellerId: id } }" class="w-12 h-12 bg-white border rounded-md flex items-center justify-center">
          <i class="bi bi-pencil-fill text-lg"></i>
        </router-link>
      </div>
    </div>
    <div class="flex flex-row justify-between space-x-2 mb-2 px-2">
      <div class="border bg-white text-xs rounded-md p-2 flex-1 text-center">Товаров: {{ productCount }}</div>
      <div class="border bg-white text-xs rounded-md p-2 flex-1 text-center">Покупателей: {{ customerCount }}</div>
      <div class="border bg-white text-xs rounded-md p-2 flex-1 text-center">Заказов: {{ orderCount }}</div>
    </div>
    <!-- Payment Options Block with Tailwind Transition -->
    <div
      v-if="showPaymentOptions && !subscriptionStatus"
      class="flex flex-col space-y-2 w-full p-2"
      :class="{ 'translate-y-0 opacity-100': showPaymentOptions, '-translate-y-4 opacity-0': !showPaymentOptions }"
    >
      <div class="text-sm font-medium">Оплатить подписку</div>
      <div class="flex justify-between w-full space-x-2">
        <button
          v-for="(month, index) in [1, 3, 6, 12]"
          :key="index"
          :class="{'bg-white outline-none focus:outline-none focus:ring focus:border-blue-500': selectedMonth === month, 'border-gray-300': selectedMonth !== month}"
          @click="selectedMonth = month"
          class="text-slate-600 font-normal px-2 py-1 rounded flex-1 border"
        > {{ month }} мес.
        </button>
      </div>
      <div class="flex w-full font-semibold justify-center">{{ totalCost }} ₽</div>
      <button 
        class="bg-lime-200 hover:bg-lime-300 border text-slate-800 font-medium py-2 px-4 rounded w-full" 
        @click="handlePayment">
          Оплатить {{ selectedMonth }} мес.
      </button>
    </div>
    <Alert v-if="showAlert" message="Ссылка успешно скопирована!" @close="handleAlertClose" />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useSubscriptionStore } from '@/stores/useSubscriptionStore';
import { useWebAppNavigation } from 'vue-tg'; // Import the navigation hook
import { useSellerStore } from '@/stores/useSellerStore'; // Import the seller store
import { Alert } from 'vue-tg'; // Assuming Alert is a component from vue-tg
import CabinetLogo from '@/components/CabinetLogo.vue'; // Import the CabinetLogo component

export default {
  name: 'SellerCard',
  components: {
    Alert,
    CabinetLogo,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      default: '',
    },
    latestSubscription: {
      type: Object,
      default: () => ({}),
    },
    productCount: {
      type: String,
      default: 0,
    },
    orderCount: {
      type: String,
      default: 0,
    },
    customerCount: {
      type: String,
      default: 0,
    },
    imageSrc: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      required: true,
    },
    iconName: { // Add a prop to specify which icon to display
      type: String,
      default: 'avito', // Default to 'icon1'
    },
  },
  setup(props) {
    const selectedMonth = ref(1);
    const costPerMonth = 500;
    const appUrl = process.env.VUE_APP_URL + '/?sellerId=' + props.id;
    const isClicked = ref(false);
    const showPaymentOptions = ref(false);
    const showAlert = ref(false);

    const togglePaymentOptions = () => {
      showPaymentOptions.value = !showPaymentOptions.value;
    };

    const handleButtonClick = () => {
      copyToClipboard(appUrl);
      isClicked.value = true;
      showAlert.value = true;

      setTimeout(() => {
        isClicked.value = false;
      }, 300); // Reset the button state after 300ms
    };

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard:', text);
      });
    };

    const handleAlertClose = () => {
      showAlert.value = false;
    };

    const handlePayment = async () => {
      const { createInvoiceLink, createSubscription } = useSubscriptionStore();
      const { openInvoice, onInvoiceClosed } = useWebAppNavigation();
      const sellerStore = useSellerStore(); // Initialize the seller store

      const currentDate = new Date();
      const endDate = new Date(currentDate);
      endDate.setMonth(currentDate.getMonth() + selectedMonth.value);

      const payload = {
        sellerId: props.id,
        title: `Подписка для кабинета Avito ${props.name}`,
        description: `Подписка на сервис Telegram Seller с ${currentDate.toLocaleDateString('ru-RU')} до ${endDate.toLocaleDateString('ru-RU')}`,
        prices: [{
          label: `${props.name} подписка на сервис Telegram Seller`,
          amount: totalCost.value * 100,
        }],
        payload: `sellerId=${props.id}&subscriptionId=5`,
      };

      try {
        const invoiceLink = await createInvoiceLink(payload);
        console.log('Invoice link:', invoiceLink);

        openInvoice(invoiceLink, async (status) => {
          console.log(`Invoice status: ${status}`);
          if (status === 'paid') {
            const subscriptionPayload = {
              sellerId: props.id,
              start_date: currentDate.toISOString().split('T')[0],
              end_date: endDate.toISOString().split('T')[0],
              is_active: true,
              is_trial: false,
              price: totalCost.value,
            };

            try {
              const subscriptionResponse = await createSubscription(subscriptionPayload);
              console.log('Subscription created:', subscriptionResponse);

              // Fetch updated sellers data after subscription creation
              await sellerStore.fetchSellers();
            
              window.location.reload();
              
            } catch (error) {
              console.error('Failed to create subscription:', error);
            }
          } else if (status === 'cancelled') {
            console.log('Invoice was cancelled.');
          } else if (status === 'failed') {
            console.log('Invoice payment failed.');
          } else if (status === 'pending') {
            console.log('Invoice payment is pending.');
          }
        });

        onInvoiceClosed(({ status }) => {
          console.log(`Invoice closed with status: ${status}`);
        });

      } catch (error) {
        console.error('Failed to create invoice link:', error);
      }
    };

    const totalCost = computed(() => {
      return selectedMonth.value * costPerMonth;
    });

    const subscriptionStatus = computed(() => {
      return props.latestSubscription?.is_active || false;
    });

    const formattedPaymentToDate = computed(() => {
      return props.latestSubscription?.end_date ? new Date(props.latestSubscription.end_date).toLocaleDateString('ru-RU') : '';
    });

    return {
      selectedMonth,
      appUrl,
      isClicked,
      showPaymentOptions,
      togglePaymentOptions,
      handleButtonClick,
      copyToClipboard,
      handleAlertClose,
      handlePayment,
      totalCost,
      subscriptionStatus,
      formattedPaymentToDate,
      showAlert,
    };
  },
};
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
