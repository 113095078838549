<template>
    <BackButton />
    <div class="p-6">
        <h1 class="text-2xl font-bold my-4">Процесс создания магазина в Telegram Seller</h1>
        <div class="text-base text-slate-700 mb-2">
            
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Создайте телеграм бота в @BotFather и получите токен.</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Скопируйте ссылку на кабинет в Avito</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Добавьте новый кабинет Avito в Telegram Seller</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Введите название кабинета</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Вставьте ссылку на кабинет Avito. В поле останется только ID кабинета</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Вставьте токен бота полученный в @BotFather</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Оплатите подписку Telegram Seller</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Получите ссылку на ваш магазин в Telegram</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Добавьте полученную ссылку для кнопки бота.</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Добавьте бота в ваш канал</span>
</div>
<div class="text-slate-700 text-lg font-bold my-4">Создание отдельного приложения для бота</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Создайте новое приложение в @BotFather для созданного бота</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Вставьте ссылку на ваш магазин в приложение</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Добавьте другую информацию которую запросит у вас @BotFather</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Получите ссылку на ваше приложение.</span>
</div>
<div class="text-slate-700 font-medium mb-2 flex items-center">
    <i class="bi bi-caret-right mr-2"></i>
    <span>Полученную ссылку вы можете публиковать на канале вставляя ее в сообщение. Клик по этой ссылке приведет к открытию вашего магазина в любом чате, из любой страницы в интернете. Ссылку можно добавить в QR код который можно разместить в OFF Line.</span>
</div>
        </div>
        
            <router-link :to="{ name: 'AddSeller' }" >
        <div class="text-md font-medium cursor-pointer p-2 my-6 rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white text-center">
            Перейти к добавлению кабинета <i class="bi bi-arrow-right"></i>
        </div>
</router-link>
    </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';

export default {
    components: {
        BackButton,
    },
};
</script>

<style scoped>
</style>
