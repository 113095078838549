<template>
    <div>
      <div v-if="iconName === 'avito'" :class="iconClasses">
        <img :src="require('@/assets/avito.svg')" :width="width" :height="height" alt="Avito Logo" />
      </div>
      <div v-else-if="iconName === 'ozon'" :class="iconClasses">
        <!-- SVG code for icon2 -->
        <img :src="require('@/assets/ozon.svg')" :width="width" :height="height" alt="Ozon Logo" />
      </div>
      <div v-else-if="iconName === 'wb'" :class="iconClasses">
        <!-- SVG code for icon3 -->
        <img :src="require('@/assets/wb.svg')" :width="width" :height="height" alt="Wb Logo" />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CabinetLogo',
    props: {
      iconName: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        default: '24', // Default width
      },
      height: {
        type: String,
        default: '24', // Default height
      },
    },
    computed: {
      iconClasses() {
        return 'w-6 h-6 flex justify-center items-center bg-gray-100 rounded-lg mr-1';
      },
    },
  };
  </script>
  
  <style scoped>
  /* No additional styles needed as Tailwind CSS is used */
  </style>
  