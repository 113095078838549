import { defineStore } from 'pinia';
import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}`;

export const useSellerStore = defineStore('seller', {
  state: () => ({
    sellers: [],
  }),
  actions: {
    async fetchSellers() {
      try {
        const response = await axios.get(`${API_URL}/api/sellers`);
        this.sellers = response.data.data;
      } catch (error) {
        console.error('Failed to fetch sellers:', error);
      }
    },    
    async addSeller(seller) {
      try {
        const response = await axios.post(`${API_URL}/api/sellers`, seller);
        this.sellers.push(response.data);
        return { success: true };
      } catch (error) {
        if (error.response && error.response.status === 409) {
          const message = error.response.data.message || 'Селлер или бот уже есть';
          return { success: false, message };
        }
        console.error('Failed to add seller:', error);
        return { success: false, message: 'Селлер не добавлен.' };
      }
    },
    async updateSeller(seller) {
      try {
        // Construct the payload with the required structure
        const payload = {
          sellerId: seller.sellerId,
          token: seller.telegraph_bots.token,
          name: seller.name,
          manager_id: seller.telegraph_bots.manager_id,
        };

        const response = await axios.put(`${API_URL}/api/sellers`, payload);
        const index = this.sellers.findIndex(s => s.id === seller.id);
        if (index !== -1) {
          this.sellers.splice(index, 1, response.data);
        }
      } catch (error) {
        console.error('Failed to update seller:', error);
      }
    },
    async deleteSeller(sellerId) {
      try {
        await axios.delete(`${API_URL}/api/sellers/${sellerId}`);
        this.sellers = this.sellers.filter(s => s.sellerId !== sellerId);
        return `Кабинет ${seller.name} успешно удалён.`;      
      } catch (error) {
        console.error('Failed to delete seller:', error);
        return 'Ошибка при удалении кабинета ${seller.name}.';
      }
    },
  },
});
