<template>
  <div id="app" class="max-w-[385px] mx-3">
    <router-view/>
  </div>
</template>

<script>

export default {
};
</script>

<style>
</style>
