<script setup>
import { BackButton as TgBackButton } from 'vue-tg';
import { MainButton } from 'vue-tg'
import { useRouter } from 'vue-router';

// Function to handle the back button click
const router = useRouter();

function handleMainButton() {
  router.back();
}
</script>

<template>
  <MainButton @click="handleMainButton" />
</template>

<style scoped>
/* Add any styles specific to the back button here */
</style>
