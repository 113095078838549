<template>
  <BackButton />
  <div class="container mx-auto">
    <div v-if="successMessage" class="bg-green-100 text-green-800 py-2 px-4 rounded-md mb-4">
      {{ successMessage }}
    </div>
    <div class="my-3">
      <router-link to="/seller/add">
        <button class="w-full font-semibold text-sky-950 py-3 px-4 rounded-xl mb-4 border border-2 border-sky-500 flex items-center space-x-2">
          <i class="bi bi-plus-circle text-lg"></i> 
          <CabinetLogo :iconName="iconName" width="20" height="20" /> 
          <div>Кабинет Avito</div>
        </button>
      </router-link>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
      <SellerCard
        v-for="(seller, index) in sellers"
        :key="index"
        :id="seller.sellerId"
        :name="seller.name"
        :productCount="seller.productCount"
        :telegramBot="seller.telegramBot"
        :latestSubscription="seller.latestSubscription"
        :orderCount="seller.orderCount"
        :iconName="wb"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useSellerStore } from '../stores/useSellerStore';
import SellerCard from '../components/seller/SellerCard.vue';
import BackButton from '../components/BackButton.vue';
import CabinetLogo from '@/components/CabinetLogo.vue'; // Import the CabinetLogo component

export default {
  name: 'SellerListPage',
  components: {
    SellerCard,
    BackButton,
    CabinetLogo,
  },
  props: {
    iconName: { // Add a prop to specify which icon to display
      type: String,
      default: 'avito', // Default to 'icon1'
    },
  },
  setup() {
    const sellers = ref([]);
    const successMessage = ref('');

    // Fetch sellers when the component is mounted
    onMounted(async () => {
      const sellerStore = useSellerStore();
      await sellerStore.fetchSellers(); // Fetch sellers
      sellers.value = sellerStore.sellers.map(seller => ({
        ...seller,
        latestSubscription: ref(seller.latestSubscription || {}),
      }));
      const query = new URLSearchParams(window.location.search);
      successMessage.value = query.get('message') || '';

      // Set a timeout to clear the success message after 2 seconds
      if (successMessage.value) {
        setTimeout(() => {
          successMessage.value = '';
        }, 2000);
      }
    });

    return {
      sellers,
      successMessage,
    };
  },
};
</script>

<style scoped>
/* Add styles if needed */
</style>
