import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import './assets/tailwind.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { VueTelegramPlugin } from 'vue-tg'


const app = createApp(App);

app.use(router);
app.use(createPinia());
app.use(VueTelegramPlugin)

app.mount('#app');
