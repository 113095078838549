import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}`;

export function useSubscriptionStore() {
  const createInvoiceLink = async (payload) => {
    try {
      const response = await axios.post(`${API_URL}/api/createInvoiceLink`, payload);
      return response.data.result;
    } catch (error) {
      console.error('Error creating invoice link:', error);
      throw error;
    }
  };

  const createSubscription = async (subscriptionPayload) => {
    try {
      const response = await axios.post(`${API_URL}/api/subscriptions`, subscriptionPayload);
      return response.data;
    } catch (error) {
      console.error('Error creating subscription:', error);
      throw error;
    }
  };

  return {
    createInvoiceLink,
    createSubscription,
  };
}
