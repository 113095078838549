<template>
    <MainButton text="Техническая поддержка" @click="handleMainButton" />
    <ExpandedViewport />
    
    <div>
        <div class="text-sm font-medium text-slate-600 flex items-center justify-start my-4">
            <div class="text-lg font-medium text-slate-600">Добро пожаловать, {{ first_name }}</div>
        </div>
        <img src="@/assets/tg_seller_main.png" alt="Description of image" class="mb-3 rounded-lg" />
        
        <div class="grid grid-cols-1 gap-3">
            <router-link :to="{ name: 'AboutPage' }">
                <div class="border text-white rounded-lg px-5 py-4 flex items-center justify-center bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                    <div class="flex-[1] flex flex-col place-items-center mr-6">
                        <i class="bi bi-info-circle text-4xl"></i>
                    </div>
                    <div class="flex-[9] flex flex-col items-start">
                        <div class="text-lg font-semibold mb-1">О сервисе</div>
                        <div class="text-xs">Информация о сервисе Telegram Seller</div>
                    </div>
                </div>
            </router-link>
            <router-link :to="{ name: 'SellerList' }">
                <div class="border text-white rounded-lg px-5 py-4 flex items-center justify-center bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                    <div class="flex-[1] flex flex-col place-items-center mr-6">
                        <i class="bi bi-grid-3x2-gap-fill text-4xl"></i>
                    </div>
                    <div class="flex-[9] flex flex-col items-start">
                        <div class="text-lg font-semibold mb-1">Список ваших кабинетов</div>
                        <div class="text-xs">Добавляйте, редактируйте, удаляйте кабинеты на маркетплейсах.</div>
                    </div>
                </div>
            </router-link>
        </div>
      
    </div>
</template>

<script>
import { useWebApp } from 'vue-tg';
import { useWebAppNavigation, ExpandedViewport  } from 'vue-tg'; // Import the navigation hook
import MainButton from '../components/MainButton.vue';

// Retrieve the first_name from the Telegram Web App context
const { initDataUnsafe } = useWebApp();
const first_name = initDataUnsafe?.user.first_name;

export default {
    components: {
        MainButton,
    },
    setup() {
        const { openTelegramLink } = useWebAppNavigation();

        const handleMainButton = () => {
            openTelegramLink(`https://t.me/apiproru`);
        };

        return {
            handleMainButton,
            first_name,
        };
    },
};
</script>
