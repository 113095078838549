// src/stores/useAuthStore.js
import { defineStore } from 'pinia';
import axios from 'axios';
import { useWebApp } from 'vue-tg';

const API_URL = `${process.env.VUE_APP_API_URL}`;

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem('jwt_token') || null,
  }),
  actions: {
    async login(credentials) {
      try {
        const response = await axios.post(`${API_URL}/api/account/login`, credentials);
        this.token = response.data.access_token;        
        this.expires_in = Date.now() + response.data.expires_in * 1000;
        localStorage.setItem('jwt_token', this.token);
        localStorage.setItem('expires_in', this.expires_in);
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
      } catch (error) {
        console.error('Login failed:', error);
        throw error;
      }
    },
    logout() {
      this.token = null;
      localStorage.removeItem('jwt_token');
      delete axios.defaults.headers.common['Authorization'];
    },
    isAuthenticated: async function() {
      this.token = localStorage.getItem('jwt_token');
      this.expires_in = Number(localStorage.getItem('expires_in'));
    
      if (this.token && this.expires_in > Date.now()) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
        return true;
      } else {
        try {
          const { initDataUnsafe } = useWebApp();
          const managerId = String(initDataUnsafe?.user.id);
          const name = initDataUnsafe?.user.first_name;
          const credentials = { name: name, telegram_user_id: managerId };
          
          await this.login(credentials); // Ожидаем завершения login
          return true; // Если login завершился успешно, считаем аутентифицированным
        } catch (error) {
          console.error('Login failed:', error);
          return false; // Если login завершился с ошибкой, не аутентифицирован
        }
      }
    },      
  },
});
